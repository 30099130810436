
html {
  scroll-behavior: smooth;
}

a, a:visited, a:target {
  color: #b3567c;
  text-decoration: none;
}

table {
  width: 100%;
  border-spacing: 0;
}

td, th {
  text-align: left;
  padding: 0.2rem 1rem;
  border-bottom: 1px solid lightgray;
}

th {
  background-color: #7ab556;
  color: #fff
}

input[type=checkbox] {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  transition: box-shadow .3s;
  background: lightgrey;
  cursor: pointer;
  border: 0;
  appearance: none; -webkit-appearance: none;
  vertical-align: middle;
}

input:checked {
  box-shadow: inset 0 0 40px #7ab556;
}

.columnDone {
  width: 5%
}

.columnPlant {
  width: 30%
}

.pageHeader {
  margin: 0 0 1rem;
  font-weight: bold;
  font-size: 1.2rem;
  color: #b3567c;
}

.anchorLinks {
  margin-bottom: 2rem;
}

hr {
  margin: 4rem auto 1rem;
  width: 98%;
  border: 2px solid;
  border-radius: 5px;
}

@media print {
  * {
    font: 12pt Georgia, "Times New Roman", Times, serif;
    line-height: 1.3;
    color: #000 !important;
  }

  header, nav, .anchorLinks {
    display: none;
  }
}